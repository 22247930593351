import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ProcessSections from '../../utils/processSections'

const MainContentSyles = styled.div``

const SectionStyles = styled.div``

export default function DistrictOfFirsts({ data }) {
  const content = data.pageContent.nodes[0]

  return (
    <MainContentSyles className="content-width">
      <ProcessSections sections={content} SectionStyles={SectionStyles} />
    </MainContentSyles>
  )
}

export const HistoryOfFirstsContent = graphql`
  query HistoryOfFirstsQuery {
    pageContent: allSanityPageContent(
      filter: { location: { eq: "/district-of-firsts" } }
    ) {
      nodes {
        id
        content: _rawContent(resolveReferences: { maxDepth: 10 })
        title
        displayTitle
      }
    }
  }
`
