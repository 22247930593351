import React from 'react'
import styled from 'styled-components'

import ProcessRichText from './processRichText'

const DefaultListStyles = styled.div``

const DefaultSectionStyles = styled.div``

export default function ProcessSections({
  sections,
  ListStyles = DefaultListStyles,
  SectionStyles = DefaultSectionStyles,
  debug = false,
}) {
  const sectionBlocks = sections.content

  const title = sections?.displayTitle

  const titleArea = title ? <h1 className="title">{title}</h1> : <></>

  return (
    <ListStyles>
      {titleArea}
      {sectionBlocks.map(section => {
        let sectionTitle = <></>

        if (section.displayTitle) {
          sectionTitle = <h2 className="section-title">{section.title}</h2>
        }

        return (
          <div key={section._key}>
            {sectionTitle}
            <ProcessRichText
              curProp={section}
              Styles={SectionStyles}
              debug={debug}
            />
          </div>
        )
      })}
    </ListStyles>
  )
}
